/* eslint-disable */
import * as Types from '../../types.gen'

import { gql } from '@apollo/client/index.js'
import { EventDetailsFragmentDoc } from '../../fragments/eventDetails.fragment.gen'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type GetLatestEventsQueryVariables = Types.Exact<{
  pagination: Types.ForwardPagination
  cityId: Types.Scalars['Int']['input']
}>

export type GetLatestEventsQuery = {
  __typename?: 'Query'
  getLatestEvents: {
    __typename?: 'EventConnection'
    edges: Array<{
      __typename?: 'EventEdge'
      cursor: any
      node: {
        __typename?: 'Event'
        id: string
        published?: boolean | null
        slug: string
        name?: string | null
        description?: string | null
        picture?: string | null
        startTime: string
        isSaved?: boolean | null
        venueAccepted?: boolean | null
        artistIdsAccepted: Array<string>
        promoterAccepted?: boolean | null
        allParticipantsAccepted?: boolean | null
        products: Array<{
          __typename?: 'Product'
          id: string
          name: string
          quantity: number
          price: { __typename?: 'Price'; amount: number }
        }>
        venue?: {
          __typename?: 'Venue'
          id: string
          name: string
          slug: string
          picture?: string | null
          ageRestriction?: Types.AgeRestriction | null
          city: { __typename?: 'City'; id: number; name: string }
        } | null
        genres: Array<{ __typename?: 'Genre'; id: number; name: string } | null>
        artists: Array<{
          __typename?: 'Artist'
          id: string
          name: string
          slug: string
          picture?: string | null
          genres: Array<{ __typename?: 'Genre'; id: number; name: string }>
        }>
        promoter?: {
          __typename: 'Promoter'
          id: string
          name: string
          description: string
          picture?: string | null
          slug: string
          links: Array<{ __typename?: 'Link'; name: string; url: string }>
        } | null
      }
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: any | null
    }
  }
}

export const GetLatestEventsDocument = gql`
  query getLatestEvents($pagination: ForwardPagination!, $cityId: Int!) {
    getLatestEvents(pagination: $pagination, cityId: $cityId) {
      edges {
        node {
          ...EventDetails
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${EventDetailsFragmentDoc}
`

/**
 * __useGetLatestEventsQuery__
 *
 * To run a query within a React component, call `useGetLatestEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestEventsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useGetLatestEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestEventsQuery,
    GetLatestEventsQueryVariables
  > &
    (
      | { variables: GetLatestEventsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLatestEventsQuery, GetLatestEventsQueryVariables>(
    GetLatestEventsDocument,
    options,
  )
}
export function useGetLatestEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestEventsQuery,
    GetLatestEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLatestEventsQuery,
    GetLatestEventsQueryVariables
  >(GetLatestEventsDocument, options)
}
export function useGetLatestEventsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLatestEventsQuery,
    GetLatestEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetLatestEventsQuery,
    GetLatestEventsQueryVariables
  >(GetLatestEventsDocument, options)
}
export type GetLatestEventsQueryHookResult = ReturnType<
  typeof useGetLatestEventsQuery
>
export type GetLatestEventsLazyQueryHookResult = ReturnType<
  typeof useGetLatestEventsLazyQuery
>
export type GetLatestEventsSuspenseQueryHookResult = ReturnType<
  typeof useGetLatestEventsSuspenseQuery
>
export type GetLatestEventsQueryResult = Apollo.QueryResult<
  GetLatestEventsQuery,
  GetLatestEventsQueryVariables
>
